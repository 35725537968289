import React,{useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Login from './Login';
import Home from './containers/Home';
import Balance from './containers/Balance';
import History from './containers/History';
import Packages from './containers/Packages';
import reportWebVitals from './reportWebVitals';
import Register from './Register';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import NotFound from './containers/NotFound';
const root = ReactDOM.createRoot(document.getElementById('root'));

const App2 = () => {
  const [dataUser, SetDataUser] = useState(false)
  return (
    <React.Fragment>
    <Router>
      <Switch>
        <Route key="1" exact path={`/`} render={props => <Login  {...props} SetDataUser={SetDataUser}/>}></Route>
        <Route key="2" exact path={`/traking`} render={props => <Home  {...props} dataUser={dataUser}/>}></Route>
        <Route key="3" exact path={`/balance`} render={props => <Balance  {...props} dataUser={dataUser}/>}></Route>
        <Route key="4" exact path={`/history`} render={props => <History  {...props} dataUser={dataUser}/>}></Route>
        <Route key="5" exact path={`/package`} render={props => <Packages  {...props} dataUser={dataUser}/>}></Route>
        <Route key="6" exact path={`/register`} render={props => <Register  {...props} dataUser={dataUser}/>}></Route>
        <Route key="10" component={NotFound} />
      </Switch>
    </Router>
    </React.Fragment>
  )}
root.render(
  <App2/>
);


reportWebVitals();
