import React ,{useEffect} from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListIcon from '@mui/icons-material/List';
import IconButton from '@mui/material/IconButton';
import TimelineIcon from '@mui/icons-material/Timeline';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import LogoutIcon from '@mui/icons-material/Logout';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import BalanceIcon from '@mui/icons-material/Balance';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
export default function Header({history,dataUser}) {
const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
});
const [scrollPosition, setScrollPosition] = React.useState(0);
useEffect(() => {
    function handleScroll() {
        const currentPosition = window.scrollY;
        setScrollPosition(currentPosition);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
    }, []);
const toggleDrawer = (anchor, open) => (event) => {
    if (
    event &&
    event.type === 'keydown' &&
    (event.key === 'Tab' || event.key === 'Shift')
    ) {
    return;
    }

    setState({ ...state, [anchor]: open });
};

const handleExist = () =>{
    localStorage.removeItem('dataUser');
    localStorage.removeItem('lastSesion');
    history.push("/")
}
const handleContact = () => {
    const message = "Hola, tengo una consulta.";
    const number = "+50768149458";
    window.open(`https://api.whatsapp.com/send?phone=${number}&text=${message}`);
  }
  const isHBClient = (dataUser.PinNo ?? '').toLowerCase().includes('hb') === true
const list = (anchor) => (
    <Box
    sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
    role="presentation"
    onClick={toggleDrawer(anchor, false)}
    onKeyDown={toggleDrawer(anchor, false)}
    >
    <List>
        <ListItem disablePadding>
            <ListItemButton onClick={()=>history.push('/traking')}>
                <ListItemIcon>
                    <LocalShippingIcon />
                </ListItemIcon>
                <ListItemText primary="Paquetes en Tránsito" />
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton onClick={()=>history.push('/package')}>
                <ListItemIcon>
                    <ShareLocationIcon />
                </ListItemIcon>
                <ListItemText primary="Rastrea tu paquete" />
            </ListItemButton>
        </ListItem>
        {isHBClient?<ListItem disablePadding>
            <ListItemButton onClick={()=>history.push('/balance')}>
                <ListItemIcon>
                    <BalanceIcon />
                </ListItemIcon>
                <ListItemText primary="Balance de Libras" />
            </ListItemButton>
        </ListItem>:null}
        <ListItem disablePadding>
            <ListItemButton onClick={()=>history.push('/history')}>
                <ListItemIcon>
                    <TimelineIcon />
                </ListItemIcon>
                <ListItemText primary="Historial de Paquetes" />
            </ListItemButton>
        </ListItem>
        <ListItem  disablePadding>
            <ListItemButton onClick={()=>handleContact()}>
            <ListItemIcon>
                <WhatsAppIcon />
            </ListItemIcon>
            <ListItemText primary="Asistencia a Cliente" />
            </ListItemButton>
        </ListItem>
    </List>
    <Divider />
    <List>
        <ListItem  disablePadding>
            <ListItemButton onClick={()=>handleExist()}>
            <ListItemIcon>
                <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Salir" />
            </ListItemButton>
        </ListItem>
    </List>
    </Box>
);

return (
    <Box className='header'>
    {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
        <Button onClick={toggleDrawer(anchor, true)}>
            <IconButton aria-label="delete" component="span">
                <ListIcon sx={{ color: '#fff' }}/>
            </IconButton>
        </Button>
        <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
        >
            {list(anchor)}
        </SwipeableDrawer>
        </React.Fragment>
    ))}
    <div style={{color:"#fff", fontWeight:'bold'}}>{dataUser.Nombre}</div>
    <div style={{width:'64px'}}>
        &nbsp;
    </div>
    </Box>
);
}