import React from 'react';

class NotFound extends React.Component{
  render(){
    return(
        <div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'100vh'}}><h1 style={{textAlign:'center'}}>Lo sentimos, ruta incorrecta</h1></div>
    )
  }
  };

export default NotFound;
